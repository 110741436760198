<template>
  <section>
    <div class="content-header">
      <h2>Enhancing students’ career resilience </h2>
    </div>
    <div class="content-wrapper">
      <p>The University of Edinburgh undertook a research project in 2018 to explore ways in which students can be helped and encouraged to develop tools for career resilience.</p>
      <p>Here are some key findings from the study:</p>
      <div class="accordion">
              <accordion
                headerText="A safe space to fail"
                accordionID="collapse01"
              >
                <p>Post-secondary institutions could consider whether their course design and assessment practices encourage students to take risks and allow them safe opportunities to fail.</p>
              </accordion>
              <accordion
                headerText="Changing perspectives"
                accordionID="collapse02"
              >
                <p>Workshops could offer the opportunity to reflect differently on experiences of failure.</p>
              </accordion>
              <accordion
                headerText="Challenging unhelpful thinking"
                accordionID="collapse03"
              >
                <p>Negative thinking patterns (such as the tendency to over-personalize feedback, or to catastrophize) need to be addressed.</p>
              </accordion>
              <accordion
                headerText="Concrete information"
                accordionID="collapse04"
              >
                <p>Students need concrete information about what to expect in the graduate labour market and what may be required to achieve success.</p>
              </accordion>
              <accordion
                headerText="Seeking help"
                accordionID="collapse05"
              >
               <p>It is important that students are aware of the full range of support available to them and when and how they can access that support.</p>
              </accordion>
              <accordion
                headerText="Advice-givers"
                accordionID="collapse06"
              >
               <p>Students had lots of useful advice for their peers, which we should capitalize on and encourage. Being in the position of giving advice sometimes encouraged them to think differently about their own situations.</p>
              </accordion>
            </div>
            <p>Learn more about how the findings from this study, <a href="https://www.amosshe.org.uk/resources/Documents/AMOSSHE-Insight-2016-17-Edinburgh.pdf" target="_blank">Enhancing students’ career resilience: Growing through failure</a> can help you support resilience in your students.</p>
            <p>How can you incorporate these evidence-based strategies in your classroom?</p>
    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
